// import logo from './logo.svg';
import React, { useState } from 'react';
import './App.css';
import ChatBotButton from './Components/ChatBotButton';

const lightTheme = {
  chatBackground: {
    bgColor: "#f5f5f5",
  },
  aiChatStrip: {
    bgColor: "#ffffff",
    iconBg: "rgb(187, 163, 60)",
  },
  myChatStrip: {
    bgColor: "#f5f5f5",
  },
  form: {
    text: "Ask Intent AI...",
  },
  chatButton: {
    bgColor: "rgb(187, 163, 60)",
    color: "#000000",
  },
  chatHeader: {
    logo: false,
    image: "",
    bgColor: "rgb(187, 163, 60)",
    textColor: "#FFFFFF",
    text: "Intent-AI",
    fontStyle: ""
  },
};

  /**
* This function is called when the user clicks on the send button prevent it from submitting form when the message is first and PreChat Survey setting is on
* @params : None
* @response : None
* @author : Milan Rawat
*/
const handleSendMessage = (e) => {
  console.log("handleSendMessage")
  e.preventDefault()
  
}
function App() {
  const [projectThemeSettings, setProjectThemeSettings] = useState(lightTheme);
  return (
    <ChatBotButton projectThemeSettings={projectThemeSettings} handleSendMessage={handleSendMessage} >
      <div id="rtecba-18205321-body-container">
        {/*iframe goes here. */}
        <iframe src="https://www.intent-ai.io/en/keratin-skala/" frameborder="0" width="100%" height="100%">
    </iframe>
      </div>
    </ChatBotButton>
  );
}

export default App;
