// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 575px) {
    #intent-ai-app-root #rtecba-18205321-slide-div {
        width: 350px;
    }
}
#intent-ai-app-root .rtecba-18205321-downarrow-to-close-chat {
    font-size: 24px;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ChatBotButton/style.css"],"names":[],"mappings":"AAAA;IACI;QACI,YAAY;IAChB;AACJ;AACA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["@media screen and (max-width: 575px) {\n    #intent-ai-app-root #rtecba-18205321-slide-div {\n        width: 350px;\n    }\n}\n#intent-ai-app-root .rtecba-18205321-downarrow-to-close-chat {\n    font-size: 24px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
