import React, { useState, useEffect } from 'react';
import { Button as ButtonAntd } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import ForumIcon from '@mui/icons-material/Forum';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import './style.css';
import logo from '../../assets/newLogoBigger.png';
import Tooltip from '@mui/material/Tooltip';
import { pickTextColorBasedOnBgColorSimple } from '../../utils/color';

const ChatBotButton = ({ projectThemeSettings, handleSendMessage, children }) => {
    const [iconChange, setIconChange] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [hover, setHover] = useState(false); // state for hover
    const [midpoint, setMidpoint] = useState(0); // state for midpoint

    const styles = {
        header: {
            color: "#000",
            width: '450px',
            height: `${isVisible ? "700px" : 0}`,
            position: 'absolute',
            bottom: '38px',
            right: 20,
            zIndex: 2,
            borderRadius: '15px',
            transition: 'all 0.5s ease',
            overflow: 'hidden',
        },
        head: {
            backgroundColor: projectThemeSettings?.chatHeader?.bgColor ? projectThemeSettings?.chatHeader?.bgColor : "rgb(187, 163, 60)",
            width: '450px',
            height: '75px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            transition: 'all 0.5s ease'
        },
        companyName: {
            color: projectThemeSettings?.chatHeader?.textColor ? projectThemeSettings.chatHeader.textColor : "#fff",
            fontFamily: projectThemeSettings?.chatHeader?.fontStyle,
            padding: '20px',
            fontSize: '25px',
            fontWeight: '600',
        },
        icon: {
            color: projectThemeSettings?.chatButton?.color ? projectThemeSettings.chatButton.color : '#000',
            fontSize: projectThemeSettings?.chatButton?.iconSize ? projectThemeSettings.chatButton.iconSize : '30px',
            margin: '0px',
            transition: "transform 1s ease",
        },
        button: {
            backgroundColor: projectThemeSettings?.chatButton?.bgColor ? projectThemeSettings.chatButton.bgColor : 'rgb(187, 163, 60)',
            border: 'none',
            width: projectThemeSettings?.chatButton?.size ? projectThemeSettings.chatButton.size : '60px',
            height: projectThemeSettings?.chatButton?.size ? projectThemeSettings.chatButton.size : '60px',
            zIndex: '3',
            boxShadow: "2px 2px 6px 4px rgba(0, 0, 0, 0.4)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }
    }

    useEffect(() => {
        const div = document.getElementById("rtecba-18205321-slide-div");
        if (isVisible) {
            // Show the div by setting its height to its scrollHeight
            div.style.height = "700px";
            div.style.width = "450px";
            div.style.boxShadow = '1px 1px 20px 1px #888888';;
        } else {
            // Hide the div by setting its height to 0
            div.style.height = "0px";
            div.style.boxShadow = 'none';
        }
    }, [isVisible]);

    useEffect(() => {
        if (hover) setMidpoint(60);
        else setMidpoint(0);
    }, [hover])

    const handleOpenChatBox = () => {
        setIsVisible(true);
        setIconChange(false);
    }
    const handleCloseChatBox = () => {
        setIsVisible(false);
        setIconChange(false);
    }

    /**
    * This function handles the close button of user review slider & sets item in localStorage after checking if Don't show again is checked or not.
    * @params : none
    * @response : Hides the modal
    * @author : Kartar & Milan
    */
    return (
        <>
            <div id="rtecba-18205321-intent-ai-button" style={{ position: "fixed", bottom: '30px', right: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div id="rtecba-18205321-slide-div" style={styles.header}>
                    <div style={styles.head}>
                        <h2 style={styles.companyName} >{projectThemeSettings?.chatHeader?.text ? projectThemeSettings.chatHeader.text : "Intent-AI"}</h2>
                        {projectThemeSettings?.chatHeader?.logo && <img src={`${projectThemeSettings?.chatHeader?.image ? `/images/logo.png}` : logo}`} alt='orglogo' style={{ maxHeight: '60%', marginLeft: "20px" }} />}
                        <Tooltip title="Minimize">
                            <KeyboardArrowDownIcon onClick={handleCloseChatBox} className='rtecba-18205321-downarrow-to-close-chat' style={{ fontSize: 28, color: pickTextColorBasedOnBgColorSimple(projectThemeSettings?.chatHeader?.bgColor) }} />
                        </Tooltip>
                    </div>
                    {children}
                </div>

                <ButtonAntd
                    type="primary"
                    shape="circle"
                    icon={
                        iconChange ? (
                            <SendOutlined style={styles.icon} />
                        ) : (
                            <ForumIcon style={styles.icon} />
                        )
                    }
                    size="large"
                    style={styles.button}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={iconChange ? handleSendMessage : handleOpenChatBox}
                />
                <ButtonAntd
                    type="primary"
                    shape="circle"
                    icon={
                        iconChange ? (
                            <SendOutlined style={{ ...styles.icon, color: projectThemeSettings?.chatButton?.bgColor }} />
                        ) : (
                            <EditIcon style={{ ...styles.icon, color: projectThemeSettings?.chatButton?.bgColor }} />
                        )
                    }
                    size="large"
                    style={{
                        ...styles.button,
                        position: "absolute",
                        overflow: 'hidden',
                        boxShadow: "none",
                        width: midpoint,
                        height: midpoint,
                        padding: 0,
                        minWidth: 0,
                        backgroundColor: projectThemeSettings?.chatButton?.color
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={iconChange ? handleSendMessage : handleOpenChatBox}
                />
            </div>
        </>
    );
};

export default ChatBotButton;